import React from "react"
import { createPortal } from "react-dom"
import { IoIosClose } from "react-icons/io"
import * as styles from "./Modal.module.scss"

type ModalProps = {
  onClose: () => void
  children: React.ReactNode
}

const Modal = (props: ModalProps) => {
  //console.log('Rendering Modal', props)

  const container = React.useRef<HTMLDivElement>(null)

  const reFocus = (/*e*/): void => {
    // palauttaa focuksen sisällön ensimmäiseen elementtiin
    if (container.current !== null) {
      const focusableElements = "a[href], area[href], button, input, textarea, select, iframe"
      const elem = container.current.querySelectorAll(focusableElements)[0] as HTMLElement

      if (elem) elem.focus()
    }
  }

  React.useEffect(() => {
    reFocus()

    // popupin sulkeminen näppäimistöllä
    window.addEventListener("keydown", keydown)

    function keydown(e: KeyboardEvent) {
      switch (e.key) {
        case "Tab": // tab
          break
        case "Escape": // esc
          props.onClose()
          break
      }
    }

    return () => {
      window.removeEventListener("keydown", keydown)
    }
  }, [])

  if (typeof document === "undefined") return null

  return (
    <>
      {createPortal(
        <div className={styles.container} ref={container}>
          <div
            className={styles.backdrop}
            aria-modal={true}
            onClick={() => {
              props.onClose()
            }}
          />
          <div className={styles.content}>{props.children}</div>
          <button
            className={styles.closeButton}
            onClick={() => {
              props.onClose()
            }}
            onBlur={reFocus}
            title="Close"
            tabIndex={0}>
            <IoIosClose />
          </button>

          {/* Pitää olla joku focus-kohde reFocus-napin jälkeen, jotta focus ei hyppää ulos selaimesta */}
          <div aria-hidden={true} tabIndex={0} className="sr-only" />
        </div>,
        document.body
      )}
    </>
  )
}

export default Modal
