import React from "react"
import BgColor from "../layouts/BgColor"
import * as styles from "./ImageWithCaption.module.scss"

type ImageWithCaptionProps = {
  image: React.ReactNode
  caption?: string
}

const ImageWithCaption = (props: ImageWithCaptionProps) => {
  //console.log('Rendering ImageWithCaption', props)

  return (
    <div className={styles.imageWithCaption}>
      {props.image}
      {props.caption && <BgColor color="SemiTransparent" /* className={styles.caption} */>{props.caption}</BgColor>}
    </div>
  )
}

export default ImageWithCaption
