import { graphql } from "gatsby"
import React from "react"
import { CSSTransition } from "react-transition-group"
import DatoImage from "../../dato/DatoImage"
import SpConsentField, { SpConsentFieldProps } from "./fields/SpConsentField"
import SpFormField, { SpFormFieldProps } from "./fields/SpFormField"
import SpOptionsField, { SpOptionsFieldProps } from "./fields/SpOptionsField"
import SpProjectScheduleField, { SpProjectScheduleFieldProps } from "./fields/SpProjectScheduleField"
import { SaunaPlannerContext } from "./SaunaPlanner"
import * as styles from "./SaunaPlannerPhase.module.scss"

type SaunaPlannerPhaseFragment = {
  id: string
  title: string
  description: string
  label: string
  backgroundImage: DatoImage
  fieldList: Array<
    | ({ __typename: "DatoCmsSpOptionsField" } & SpOptionsFieldProps)
    | ({ __typename: "DatoCmsSpConsentField" } & SpConsentFieldProps)
    | ({ __typename: "DatoCmsSpFormField" } & SpFormFieldProps)
    | ({ __typename: "DatoCmsSpProjectScheduleField" } & SpProjectScheduleFieldProps)
  >
}
export type SaunaPlannerPhaseProps = SaunaPlannerPhaseFragment

const SaunaPlannerPhase: React.FC<SaunaPlannerPhaseProps> = props => {
  //console.log("Rendering SaunaPlannerPhase", props)
  const [_state, _setState, errors] = React.useContext(SaunaPlannerContext)
  const [phase, setPhase] = React.useState<SaunaPlannerPhaseProps>()
  const [visible, setVisible] = React.useState(false)
  const hasErrors = errors?.length

  React.useEffect(() => {
    if (!phase) {
      // ensimmäinen phase, näytetään nopeammin
      setVisible(false)
      setTimeout(() => {
        // näytetään uusi data
        setPhase(props)
        setVisible(true)
      }, 0)
    } else {
      // piilotetaan vanha
      setVisible(false)
      setTimeout(() => {
        // näytetään uusi data
        setPhase(props)
        setVisible(true)
      }, 500)
    }
  }, [props.id])

  if (!phase) return null

  return (
    <section className={[styles.container, hasErrors && "hasErrors"].filter(Boolean).join(" ")}>
      <CSSTransition classNames="fade" in={visible} timeout={500}>
        <DatoImage className={styles.mainImage} {...phase.backgroundImage} />
      </CSSTransition>

      <div className={styles.content}>
        <CSSTransition classNames="zoom" in={visible} timeout={visible ? 1000 : 500}>
          <div className="layout-container">
            <h2>{phase.title}</h2>
            <p>{phase.description}</p>
            <fieldset className={styles.fieldset}>
              {phase.fieldList.map((list, i) => {
                switch (list.__typename) {
                  case "DatoCmsSpOptionsField":
                    return <SpOptionsField key={i} {...(list as SpOptionsFieldProps)} />
                  case "DatoCmsSpConsentField":
                    return <SpConsentField key={i} {...(list as SpConsentFieldProps)} />
                  case "DatoCmsSpFormField":
                    return <SpFormField key={i} {...(list as SpFormFieldProps)} />
                  case "DatoCmsSpProjectScheduleField":
                    return <SpProjectScheduleField key={i} {...(list as SpProjectScheduleFieldProps)} />
                }
              })}
            </fieldset>
          </div>
        </CSSTransition>
      </div>
    </section>
  )
}

export default SaunaPlannerPhase

export const query = graphql`
  fragment DatoSpPhase on DatoCmsSpPhase {
    id
    title
    description
    label
    backgroundImage {
      ...DatoMedia
    }
    fieldList {
      __typename
      ...DatoSpOptionsField
      ...DatoSpConsentField
      ...DatoSpFormField
      ...DatoSpProjectScheduleField
    }
  }
`
