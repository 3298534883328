import { graphql } from "gatsby"
import React from "react"
import { BiCheck, BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi"
import DatoImage from "../../../dato/DatoImage"
import ImageGalleryModal from "../../../molecules/ImageGalleryModal"
import InfoTooltip from "../../../molecules/InfoTooltip"
import { SaunaPlannerContext } from "../SaunaPlanner"
import * as styles from "./SpOptionsField.module.scss"

const AdditionalImages = (props: { images: any[] }) => {
  const [open, setOpen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)

  const next = () => {
    if (currentIndex !== null && currentIndex < props.images.length - 1) setCurrentIndex(currentIndex + 1)
    else setCurrentIndex(0)
  }
  const prev = () => {
    if (currentIndex !== null && currentIndex > 0) setCurrentIndex(currentIndex - 1)
    else setCurrentIndex(props.images.length - 1)
  }
  const openImg = (i: number) => {
    setCurrentIndex(i)
    setOpen(true)
  }

  return (
    <>
      {props.images.map((img, i) => {
        return (
          <button key={i} className={styles.imageButton} onClick={() => openImg(i)}>
            <DatoImage {...img} alt={img.alt || `Thumb ${i + 1}`} className={styles.imageThumb} />
          </button>
        )
      })}
      <ImageGalleryModal
        currentImage={props.images[currentIndex]}
        open={open}
        onClose={() => setOpen(false)}
        onNext={next}
        onPrev={prev}
      />
    </>
  )
}

type SpOptionFragment = {
  id: string
  title: string
  slug: string
  infoText?: string
  image?: DatoImage
  additionalImages?: DatoImage[]
}

type SpOptionProps = SpOptionFragment & {
  checked: boolean
  toggle: (slug: string) => void
}

export const SpOption = (props: SpOptionProps) => {
  const checked = !!props.checked
  const hasInfo = !!props.infoText || !!props.additionalImages?.length

  return (
    <>
      <input
        className={styles.input}
        id={props.id}
        type="checkbox"
        checked={checked}
        onChange={() => props.toggle(props.slug) /* event => setState({ [props.id]: event.currentTarget.checked }) */}
      />
      <div
        className={[styles.optionContainer, checked && styles.checked, props.image && styles.hasImage]
          .filter(Boolean)
          .join(" ")}>
        <label htmlFor={props.id} /*className={styles.label}*/>
          {props.image && (
            <>
              <DatoImage className={styles.image} {...props.image} alt={props.image.alt || ""} />
              <div className={styles.checkedCorner}>
                <BiCheck />
              </div>
            </>
          )}
          <div className={styles.labelArea}>
            {checked ? <BiRadioCircleMarked className={styles.circle} /> : <BiRadioCircle className={styles.circle} />}

            <span className={styles.labelText}>{props.title}</span>

            {hasInfo && (
              <span className={styles.labelInfo}>
                <InfoTooltip
                  text={
                    <>
                      {props.infoText && <div style={{ marginBottom: "1em" }}>{props.infoText}</div>}
                      {!!props.additionalImages?.length && <AdditionalImages images={props.additionalImages} />}
                    </>
                  }
                />
              </span>
            )}
          </div>
        </label>
      </div>
    </>
  )
}

export type SpOptionsFieldProps = {
  id: string
  allowMultiple: boolean
  title: string
  slug: string
  required: boolean
  spOptions: SpOptionFragment[]
}

const SpOptionsField: React.FC<SpOptionsFieldProps> = props => {
  //console.log('Rendering SpOptionsField', props)

  const [state, setState, errors] = React.useContext(SaunaPlannerContext)

  /** Valitut optionit, statessa on arvo "slug | slug | slug" */
  const values: string[] = state[props.slug]?.split(" | ") || []

  const toggleOption = (slug: string) => {
    if (props.allowMultiple) {
      if (values.includes(slug)) {
        setState({ [props.slug]: values.filter(opt => opt !== slug).join(" | ") })
      } else {
        setState({ [props.slug]: [...values, slug].filter(Boolean).join(" | ") })
      }
    } else {
      setState({ [props.slug]: values[0] === slug ? undefined : slug })
    }
  }

  return (
    <div className={[styles.container].filter(Boolean).join(" ")}>
      {props.title && (
        <legend className={styles.title}>
          {props.title} {props.required && "*"}
        </legend>
      )}
      <ul className={styles.list}>
        {props.spOptions.map((opt, i) => (
          <li
            key={i}
            className={[styles.listItem, errors.includes(props.slug) && styles.error].filter(Boolean).join(" ")}>
            <SpOption {...opt} toggle={toggleOption} checked={values.includes(opt.slug)} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SpOptionsField

export const query = graphql`
  fragment DatoSpOptionsField on DatoCmsSpOptionsField {
    id
    allowMultiple
    title
    slug
    required

    spOptions {
      id
      title
      slug
      infoText
      image {
        ...DatoImageDefault
      }
      additionalImages {
        ...DatoImageDefault
        title
        url
        alt
      }
    }
  }
`
