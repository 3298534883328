import { graphql } from "gatsby"
import React, { ReactNode } from "react"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import { SaunaPlannerContext } from "../SaunaPlanner"
import * as styles from "./SpFormField.module.scss"

/**
 * Natiivi <input type="file" /> ei voi olla controlled, joten tehdään oma komponentti, joka hallitsee tiedostot.
 *
 * */
const FileInput = props => {
  const [_state, _setState, errors] = React.useContext(SaunaPlannerContext)
  const { locale } = React.useContext(PageContext)
  const t = useDatoMicrocopy("formCommon", locale)

  const files = [...props.value]

  return (
    <div>
      <label>
        <span className={[styles.filebutton, errors.includes(props.slug) && styles.error].filter(Boolean).join(" ")}>
          {t("files.buttonLabel", "Select files")}
        </span>
        <input
          {...props.rest}
          style={{ display: "none" }}
          type="file"
          multiple={true}
          onChange={e => {
            props.onChange(e.target.files)
          }}
        />
      </label>
      {files.map((f, i) => (
        <small key={i} style={{ display: "block", opacity: 0.7 }}>
          {f.name}
        </small>
      ))}
    </div>
  )
}

export type SpFormFieldProps = {
  id: string
  title: string
  slug: string
  required: boolean
  placeholder: string
  fieldType: "Text" | "TextArea" | "Number" | "Email" | "Phone" | "Attachment"
}

const SpFormField: React.FC<SpFormFieldProps> = props => {
  //console.log('Rendering SpFormField', props)
  const [state, setState] = React.useContext(SaunaPlannerContext)

  let inputtype = "text"

  switch (props.fieldType) {
    case "Text":
      inputtype = "text"
      break
    case "Number":
      inputtype = "number"
      break
    case "Email":
      inputtype = "email"
      break
    case "Phone":
      inputtype = "tel"
      break
  }

  let input: ReactNode
  switch (props.fieldType) {
    case "Attachment":
      input = (
        <FileInput
          id={props.id}
          slug={props.slug}
          className={styles.input}
          placeholder={props.placeholder}
          value={state[props.slug] || []}
          onChange={files => setState({ [props.slug]: files })}
          required={props.required}
          accept="image/*,.pdf"
        />
      )
      break

    case "TextArea":
      input = (
        <textarea
          id={props.id}
          className={styles.input}
          placeholder={props.placeholder}
          value={state[props.slug] || ""}
          onChange={event => setState({ [props.slug]: event.currentTarget.value })}
          required={props.required}
          rows={5}
        />
      )
      break

    case "Text":
    case "Number":
    case "Email":
    case "Phone":
    default:
      input = (
        <input
          id={props.id}
          className={styles.input}
          type={inputtype}
          placeholder={props.placeholder}
          value={state[props.slug] || ""}
          onChange={event => setState({ [props.slug]: event.currentTarget.value })}
          required={props.required}
        />
      )

      break
  }

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={props.id}>
        {props.title} {props.required && "*"}
      </label>
      {input}
    </div>
  )
}

export default SpFormField

export const query = graphql`
  fragment DatoSpFormField on DatoCmsSpFormField {
    id
    title
    slug
    required
    placeholder
    fieldType
  }
`
