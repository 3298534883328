import { graphql } from "gatsby"
import React from "react"
import SaunaPlanner from "../../organisms/saunaPlanner/SaunaPlanner"
//import * as styles from "./DatoSaunaPlannerBlock.module.scss"

const DatoSaunaPlannerBlock = (props: Queries.DatoSaunaPlannerBlockFragment) => {
  //console.log("Rendering DatoSaunaPlannerBlock", props)
  if (!props.saunaPlanner || !props.saunaPlanner.phases) {
    // TODO fragments and props are mixed up, there's no clean separation between Dato and UI components
    // guard against this block somehow ending on a page where there's no or invalid Sauna Planner
    return null
  }
  return <SaunaPlanner {...props.saunaPlanner} />
}

export default DatoSaunaPlannerBlock

// Lokalisaatio menee tutummin, kun juoksutetaan data tätä kautta, vaikka kyseessä on single instance linkki
export const query = graphql`
  fragment DatoSaunaPlannerBlock on DatoCmsSaunaPlannerBlock {
    saunaPlanner {
      ...SaunaPlanner
    }
  }
`
