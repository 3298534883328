import { graphql } from "gatsby"
import React from "react"
import { StructuredTextDocument } from "react-datocms"
import DatoStructuredTextBlock from "../../../dato/DatoStructuredTextBlock"
import { SaunaPlannerContext } from "../SaunaPlanner"
import * as styles from "./SpConsentField.module.scss"

export type SpConsentFieldProps = {
  id: string
  title: string
  slug: string
  required: boolean
  description: StructuredTextDocument
}

const SpConsentField: React.FC<SpConsentFieldProps> = props => {
  //console.log('Rendering SpConsentField', props)
  const [state, setState, errors] = React.useContext(SaunaPlannerContext)

  return (
    <div className={styles.container}>
      <label className={styles.title}>
        {props.title} {props.required && "*"}
      </label>
      <div className={[styles.field, errors.includes(props.slug) && styles.error].filter(Boolean).join(" ")}>
        <input
          id={props.id}
          className={styles.input}
          type="checkbox"
          checked={!!state[props.slug]}
          onChange={event => setState({ [props.slug]: event.currentTarget.checked })}
          required={props.required}
        />
        <label className={styles.label} htmlFor={props.id}>
          <DatoStructuredTextBlock data={props.description} />
        </label>
      </div>
    </div>
  )
}

export default SpConsentField

export const query = graphql`
  fragment DatoSpConsentField on DatoCmsSpConsentField {
    id
    title
    slug
    required
    description {
      value
      links {
        __typename
        ... on DatoCmsRouteLink {
          ...DatoRouteLink
        }
        ... on DatoCmsExternalUrl {
          ...DatoExternalUrl
        }
      }
    }
  }
`
