import { graphql } from "gatsby"
import React from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import { trackEvent } from "../../../utils"
import Button from "../../atoms/Button"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import DatoImage from "../../dato/DatoImage"
import DatoInlineRecord from "../../dato/DatoInlineRecord"
import * as styles from "./SaunaPlanner.module.scss"
import SaunaPlannerPhase from "./SaunaPlannerPhase"

// TODO komponentissa GraphQL fragmentti mutta se on organismien alla. Erota data ja UI eli UI sidonnaisuus Gatsbyyn
// tämä käyttää myös DatoRouteLink ja DatoImage komponentteja suoraan
// tyypitys oli tälläinen ennen typegenin fragmentista generoimaan siirtymistä:

// type SaunaPlannerProps = {
//   basinEndpoint: string
//   title: string
//   introduction?: string
//   startLabel: string
//   mainImage: DatoImage

//   phases: Array<{ id: string } & SaunaPlannerPhaseProps>

//   completedLink?: { __typename: "DatoCmsRouteLink" } & DatoRouteLink
//   completedText?: string
//   completedTitle: string
// }

export enum RequestStatus {
  /** Request is initialized but not invoked yet. Initial state. */
  EMPTY = "Empty",
  /** Request is started and waiting for response. */
  LOADING = "Loading",
  /** Request has ended in error. Error message is available. */
  ERROR = "Error",
  /** Request ended successfully and response data is available. */
  SUCCESS = "Success",
}

type SaunaPlannerState = Record<string, any>

/**
 * [0]: Nykyinen SaunaPlannerin state eli lomakkeen kenttien arvot
 *
 * [1]: appendData -funktio, jolla voi päivittää arvoja stateen
 *
 * [2]: slug[] Nykyisen vaiheen virheelliset kentät, tai "browser-errors", jos selaimen lomakevalidointi sanoo jotain
 */
export const SaunaPlannerContext = React.createContext<
  [SaunaPlannerState, (values: SaunaPlannerState) => void, string[]]
>([{}, () => null, []])

const SaunaPlanner = (props: Queries.SaunaPlannerFragment) => {
  // console.debug("Rendering SaunaPlanner", props)

  // phase on string SaunaPlannerPhase.id tai "start" tai "end"

  const [status, setStatus] = React.useState<RequestStatus>(RequestStatus.EMPTY)
  const [phase, setPhase] = React.useState<string>("start")
  const [state, setState] = React.useState({})
  const [errors, setErrors] = React.useState<string[]>([])
  const container = React.useRef<HTMLElement>(null)
  const form = React.useRef<HTMLFormElement>(null)
  const progressBar = React.useRef<HTMLOListElement>(null)
  const progressRefs = React.useRef<HTMLLIElement[]>([])
  const { locale } = React.useContext(PageContext)
  const t = useDatoMicrocopy("common", locale)

  const activePhase = props.phases?.find(p => p?.id === phase)
  const curInd = props.phases?.findIndex(p => activePhase && p?.id === activePhase.id)

  const appendData = (values: SaunaPlannerState) => {
    //console.log(values)
    const newState = { ...state, ...values }
    setState(newState)

    // poistetaan errorit näkyvistä, jos ne on korjattu
    if (errors.length) {
      setErrors(getErrors(activePhase, newState))
    }
  }

  const showPhase = (phase: "start" | "end" | number) => {
    if (typeof phase === "string") {
      setPhase(phase)
      trackEvent("SaunaPlanner", "showPhase", phase)
    } else {
      setPhase(props.phases[phase].id)
      scrollToProgress(phase)
      trackEvent("SaunaPlanner", "showPhase", props.phases[phase].title)
    }
  }

  const getErrors = (phase, state) => {
    let errors: string[] = []

    // selaimen oma lomakevalidointi. Hoitaa email, tel, required, jne.
    if (form.current && !form.current.checkValidity()) errors.push("browser-errors")

    // pakolliset muut kentät täytetty
    const fielderrors: any[] = phase?.fieldList
      .filter(field => field.required && !state[field.slug])
      .map(field => field.slug)

    if (fielderrors?.length) {
      errors = [...errors, ...fielderrors]
    }

    return errors
  }

  const next = () => {
    const errors = getErrors(activePhase, state)
    setErrors(errors)
    scrollToTop()
    if (!errors?.length) {
      if (curInd < props.phases.length - 1) {
        showPhase(curInd + 1)
      } else {
        // submit
        showPhase("end")
      }
    }
  }

  const prev = () => {
    scrollToTop()
    if (curInd > 0) {
      setErrors([])
      showPhase(curInd - 1)
    } else {
      showPhase("start")
    }
  }

  const submit = async () => {
    //console.log(state)

    const errors = getErrors(activePhase, state)
    setErrors(errors)

    if (!errors?.length) {
      // submit
      // https://usebasin.com/f/893b72fc6c6c

      setStatus(RequestStatus.LOADING)

      const ep = props.basinEndpoint
      const endPointJson = ep + ".json"
      const data = new FormData()
      for (const key in state) {
        if (state[key] instanceof FileList) {
          for (let i = 0; i < state[key].length; i++) {
            data.append(key + i, state[key][i])
          }
        } else {
          data.append(key, state[key])
        }
      }

      data.append("locale", locale)
      data.append("website", "global")

      await fetch(endPointJson, { method: "post", headers: { Accept: "application/json" }, body: data })
        .then(() => {
          showPhase("end")
          setStatus(RequestStatus.SUCCESS)
        })
        .catch(e => {
          console.error("Error", e)
          setStatus(RequestStatus.ERROR)
        })
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: (container.current?.offsetTop || 0) - 80, // header height
      behavior: "smooth",
    })
  }

  /** (mobiilissa) scrollataan progressbar valitun indeksin kohdalle */
  const scrollToProgress = (curInd: number) => {
    if (progressBar.current) {
      const scrollTo =
        progressRefs.current[curInd].offsetLeft -
        (progressBar.current.offsetWidth - progressRefs.current[curInd].offsetWidth) * 0.5

      progressBar.current.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      })
    }
  }

  const progresssClick = (i: number) => {
    if (i < curInd) {
      showPhase(i)
    }
  }

  return (
    <SaunaPlannerContext.Provider value={[state, appendData, errors]}>
      <article ref={container} className={styles.container}>
        <div className={styles.sections}>
          {phase === "start" && (
            <section className={styles.section}>
              <DatoImage className={styles.mainImage} {...props.mainImage} alt={props.mainImage?.alt || ""} />
              <div className={styles.content}>
                <h2>{props.title}</h2>
                <p>{props.introduction}</p>
                <Button label={props.startLabel} onClick={next} />
              </div>
            </section>
          )}

          {activePhase && (
            <form ref={form} style={{ display: "flex", width: "100%" }} onSubmit={e => e.preventDefault()}>
              <SaunaPlannerPhase {...activePhase} />
            </form>
          )}

          {phase === "end" && (
            <section className={styles.section}>
              <DatoImage className={styles.mainImage} {...props.mainImage} alt={props.mainImage?.alt || ""} />
              <div className={styles.content}>
                <h2>{props.completedTitle}</h2>
                <p>{props.completedText}</p>
                {props.completedLink && <DatoInlineRecord {...props.completedLink} />}
              </div>
            </section>
          )}
        </div>

        {activePhase && (
          <>
            <ol ref={progressBar} className={styles.progress}>
              {props.phases.map((phase, i) => {
                return (
                  <li
                    ref={el => el && (progressRefs.current[i] = el)}
                    key={phase.id}
                    onClick={() => progresssClick(i)}
                    className={[styles.progressItem, curInd === i && styles.current, curInd > i && styles.completed]
                      .filter(Boolean)
                      .join(" ")}>
                    {phase.label}
                  </li>
                )
              })}
            </ol>

            <div className={styles.controls}>
              <Button label={t("previous", "Previous")} onClick={prev} size="flat" />
              {curInd < props.phases.length - 1 && <Button label={t("next", "Next")} onClick={next} size="flat" />}
              {curInd === props.phases.length - 1 && (
                <>
                  {status === RequestStatus.ERROR && (
                    <div className={styles.errorMessage}>Error occured. Try again later. </div>
                  )}
                  <div>
                    {status !== RequestStatus.LOADING && (
                      <Button
                        label={t("sendButton.label", "Send", "formCommon")}
                        onClick={submit}
                        size="flat"
                        priority="primary"
                      />
                    )}
                    <LoadingSpinner loading={status === RequestStatus.LOADING} />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </article>
    </SaunaPlannerContext.Provider>
  )
}

export default SaunaPlanner

export const query = graphql`
  fragment SaunaPlanner on DatoCmsSaunaPlanner {
    basinEndpoint
    title
    introduction
    startLabel
    mainImage {
      #...DatoMedia
      ...DatoImageDefault
    }

    phases {
      id
      ...DatoSpPhase
    }

    completedTitle
    completedText
    completedLink {
      __typename
      ...DatoRouteLink
    }
  }
`
