import React from "react"
import { BsArrowsFullscreen } from "react-icons/bs"
import { FaPinterest } from "react-icons/fa"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { useSwipeable } from "react-swipeable"
import { CSSTransition } from "react-transition-group"
import Modal from "../atoms/Modal"
import DatoImage, { DatoImageProps } from "../dato/DatoImage"
import * as styles from "./ImageGalleryModal.module.scss"
import ImageWithCaption from "./ImageWithCaption"

type ImageGalleryModalProps = {
  onClose: () => void
  onNext: () => void
  onPrev: () => void
  open: boolean
  currentImage: {
    title?: string
    url: string
  } & DatoImageProps
}

const ImageGalleryModal = (props: ImageGalleryModalProps) => {
  //console.log('Rendering ImageGalleryModal', props)

  const { open, currentImage } = props

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => props.onNext(),
    onSwipedRight: () => props.onPrev(),
    onSwipedUp: () => props.onClose(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <>
      {currentImage && (
        <CSSTransition classNames="fade" in={open} timeout={500} unmountOnExit>
          <Modal onClose={props.onClose}>
            <div style={{ display: "flex", width: "100%", height: "100%" }} {...swipeHandlers}>
              <div className={styles.fullImageCont}>
                <ImageWithCaption
                  image={
                    <DatoImage {...currentImage} className={styles.fullImage} imgStyle={{ objectFit: "contain" }} />
                  }
                  caption={currentImage?.title}
                />
              </div>
              <div className={styles.controls}>
                <button className={styles.controlButton} onClick={() => props.onPrev()}>
                  <IoIosArrowBack />
                </button>
                <button className={styles.controlButton} onClick={() => props.onNext()}>
                  <IoIosArrowForward />
                </button>
              </div>

              {typeof window !== "undefined" && (
                <div className={styles.links}>
                  <a className={styles.link} href={`${currentImage?.url}`} target="_blank" rel="nofollow noopener">
                    <BsArrowsFullscreen className={styles.pinIcon} />
                    <div>Full image</div>
                  </a>

                  <a
                    className={styles.link}
                    href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=${
                      currentImage?.url
                    }&description=${currentImage?.title || currentImage?.alt}`}
                    target="_blank"
                    rel="nofollow noopener">
                    <FaPinterest className={styles.pinIcon} />
                    <div>Pin it</div>
                  </a>
                </div>
              )}
            </div>
          </Modal>
        </CSSTransition>
      )}
    </>
  )
}

export default ImageGalleryModal
