import { graphql } from "gatsby"
import React from "react"
import SpOptionsField from "./SpOptionsField"
//import * as styles from "./SpProjectScheduleField.module.scss"

export type SpProjectScheduleFieldProps = {
  id: string
  title: string
  slug: string
  required: boolean
  option3Label: string
  option2Label: string
  option1Label: string
}

const SpProjectScheduleField: React.FC<SpProjectScheduleFieldProps> = props => {
  //console.log('Rendering SpProjectScheduleField', props)
  return (
    <SpOptionsField
      id={props.id}
      title={props.title}
      slug={props.slug}
      required={props.required}
      allowMultiple={false}
      spOptions={[
        {
          slug: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString("en-US"), // 30 päivää eteenpäin
          title: props.option1Label,
          id: "schedule1",
        },
        {
          slug: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90).toLocaleDateString("en-US"), // 90 päivää eteenpäin
          title: props.option2Label,
          id: "schedule2",
        },
        {
          slug: new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toLocaleDateString("en-US"), // 180 päivää eteenpäin
          title: props.option3Label,
          id: "schedule3",
        },
      ]}
    />
  )
}

export default SpProjectScheduleField

export const query = graphql`
  fragment DatoSpProjectScheduleField on DatoCmsSpProjectScheduleField {
    id
    title
    slug
    required
    option3Label
    option2Label
    option1Label
  }
`
